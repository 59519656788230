<template>
  <b-modal
    id="modal-produk"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="md"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <b-container class="mt-2">
        <b-row class="justify-content-center h-100 bg-white">
          <b-col cols="12">
            <h5 class="text-center text-dark">Tambah Produk</h5>
            <hr>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-row>
              <!-- Barang -->
              <b-col
                cols="12"
                md="12"
                class="mb-1 custom__form--input"
              >
                <label>Barang</label>
                <b-form-group class="w-100">
                  <v-select
                    v-model="formPayload.item_uuid"
                    label="name"
                    :options="optionsBarang"
                    placeholder="Barang"
                    @search="onSearchBarang"
                  />
                  <small
                    v-if="messages.item_uuid && Array.isArray(messages.item_uuid) && messages.item_uuid.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.item_uuid.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Brand -->
              <b-col
                cols="12"
                md="12"
                class="mb-1 custom__form--input"
              >
                <label>Brand</label>
                <b-form-group class="w-100">
                  <v-select
                    v-model="formPayload.brand_uuid"
                    label="name"
                    :options="optionsBrand"
                    placeholder="Brand"
                    @search="onSearchBrand"
                  />
                  <small
                    v-if="messages.brand_uuid && Array.isArray(messages.brand_uuid) && messages.brand_uuid.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.brand_uuid.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Satuan -->
              <b-col
                cols="12"
                md="12"
                class="mb-1 custom__form--input"
              >
                <label>Satuan</label>
                <b-form-group class="w-100">
                  <v-select
                    v-model="formPayload.uom_uuid"
                    label="name"
                    :options="optionsSatuan"
                    placeholder="Satuan"
                    @search="onSearchSatuan"
                  />
                  <small
                    v-if="messages.uom_uuid && Array.isArray(messages.uom_uuid) && messages.uom_uuid.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.uom_uuid.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Spesifikasi -->
              <b-col
                cols="12"
                md="12"
                class="mb-1 custom__form--input"
              >
                <label for="v-specification">Spesifikasi</label>
                <b-form-group>
                  <b-form-input
                    id="v-specification"
                    v-model="formPayload.specification"
                    placeholder="Spesifikasi"
                    class="custom__input"
                  />
                </b-form-group>
                <small
                  v-if="messages.specification && Array.isArray(messages.specification) && messages.specification.length > 0"
                  class="text-error fw-bold-500 size12"
                >{{ messages.specification.join(', ') }}</small>
              </b-col>
              <!-- Alias Name -->
              <b-col
                cols="12"
                md="12"
                class="mb-1 custom__form--input"
              >
                <label for="v-alias_name">Alias Name</label>
                <b-form-group>
                  <b-form-input
                    id="v-alias_name"
                    v-model="formPayload.alias_name"
                    placeholder="Alias Name"
                    class="custom__input"
                  />
                  <small
                    v-if="messages.alias_name && Array.isArray(messages.alias_name) && messages.alias_name.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.alias_name.join(', ') }}</small>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-produk')"
      >
        Batal
      </b-button>
      <b-button
        class="button__save d-flex align-items-center justify-content-center"
        :disabled="isLoading"
        @click="postData"
      >
        <!-- @click.prevent="editId ? updateItem() : submitData()" -->
        <div v-if="isLoading">
          <b-spinner variant="primary" small />
        </div>
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { mapActions } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BContainer,
    BSpinner,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      formPayload: {
        item_uuid: '',
        brand_uuid: '',
        uom_uuid: '',
        specification: '',
        alias_name: '',
      },
      photo_url: '',
      editUuid: null,
      messages: '',
      optionsBarang: [],
      optionsBrand: [],
      optionsSatuan: [],
      isLoading: false,
    }
  },
  watch: {
    iconBank(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  created() {
    this.getDataBarang()
    this.getDataBrand()
    this.getDataSatuan()
  },
  methods: {
    ...mapActions({
      getDataBrandAction: 'brand/getData',
      getDataBarangAction: 'item/getData',
      getDataSatuanAction: 'uom/getData',
      getDataProdukAction: 'produk/getData',
      postDataAction: 'produk/postData',
    }),
    async postData() {
      this.isLoading = true
      const form = this.preparePayload()
      const response = await this.postDataAction({
        id: '',
        payload: form,
      })
      if (response) {
        this.isLoading = false
        this.$bvModal.hide('modal-produk')
        this.getDataProdukAction()
        successNotification(this, 'Success', 'Produk berhasil di tambahkan')
        this.formPayload = {
          item_uuid: '',
          brand_uuid: '',
          uom_uuid: '',
          specification: '',
          alias_name: '',
        }
      }
    },
    onSearchSatuan(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSatuan(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsSatuan = []
          this.getDataSatuan()
          loading(false)
        }, 500)
      }
    },
    searchSatuan: _.debounce((loading, search, vm) => {
      vm.getDataSatuanAction({
        params: {
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsSatuan = response.data
          loading(false)
        })
    }, 300),
    async getDataSatuan() {
      const response = await this.getDataSatuanAction({
        params: '',
      })
      this.optionsSatuan = response.data
    },
    onSearchBrand(search, loading) {
      if (search.length) {
        loading(true)
        this.searchBrand(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsBrand = []
          this.getDataBrand()
          loading(false)
        }, 500)
      }
    },
    searchBrand: _.debounce((loading, search, vm) => {
      vm.getDataBrandAction({
        params: {
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsSatuan = response.data
          loading(false)
        })
    }, 300),
    async getDataBrand() {
      const response = await this.getDataBrandAction({
        params: '',
      })
      this.optionsBrand = response.data
    },
    onSearchBarang(search, loading) {
      if (search.length) {
        loading(true)
        this.searchBarang(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsBarang = []
          this.getDataBarang()
          loading(false)
        }, 500)
      }
    },
    searchBarang: _.debounce((loading, search, vm) => {
      vm.getDataBarangAction({
        params: {
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsBarang = response.data
          loading(false)
        })
    }, 300),
    async getDataBarang() {
      const response = await this.getDataBarangAction({
        params: '',
      })
      this.optionsBarang = response.data
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key].uuid || this.formPayload[key])
        }
      }
      return form
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-produk')
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';

#modal-produk {
  .modal-content {
    border-radius: 16px;

    .modal-body {
      padding: 0 !important;
    }
  }
}

.button__group {
  display: flex;
  width: 100%;

  .button__cancel {
    background-color: #FFFFFF !important;
    border-color: #fff !important;
    color: $dark !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 0px 16px;
    width: 100%;
    height: 52px;
    font-size: 14px;
    font-weight: 800;
  }

  .button__save {
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    height: 52px;
    font-size: 14px;
    font-weight: 800;
  }
}
</style>
